import moment from 'moment-timezone'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'

function userPrivateChannel(eventName) {
  if (typeof window.Echo === 'undefined') {
    // when Echo is turned off (local)
    return
  }

  const userStore = useUserStore()
  const { id, notifications } = storeToRefs(userStore)

  window.Echo.private(`user.${id.value}`).listen(eventName, event => {
    const userNotifications = notifications.value

    userNotifications.unshift({
      name: event.name,
      body: event.message,
      date: moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
    })
  })
}

export { userPrivateChannel }
